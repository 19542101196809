header {
  text-align: center;
  color: var(--bs-light);
  font-size: 36px;
  line-height: 44px;
}

main {
  background-color: var(--bs-light);

  border-radius: 2px;

  padding: 20px;
  margin: 5px 20px 20px 20px;

  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.7);

  height: 3000px;
}