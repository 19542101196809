@import url('https://fonts.googleapis.com/css2?family=Acme&display=swap');
@import "../node_modules/bootstrap/scss/functions";

$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #1abc9c;
$cyan: #17a2b8;
$white: #fff;
$gray: #6c757d;
$gray-dark: #343a40;
$primary: #c2af89;
$secondary: #343148;
$success: #28a745;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;
$light: #cccccc;
$dark: #343a40;
$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$font-family-sans-serif: 'Acme', sans-serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

@import '~bootstrap/scss/bootstrap.scss';

body {
  font-family: $font-family-base;
  background-color: $dark;
}